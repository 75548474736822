<template>
    <layout>
        <v-row
            align="center"
            justify="center"
        >
            
                <v-card
                    width="800"
                >
                    <v-card-title
                        class="primary white--text"
                    >
                        Student Attendance Records
                    </v-card-title>

                    <v-card-text class="pb-0">
                    <v-container>
                        <v-row>
                            <v-col cols="4">
                                <v-card flat>
                                    <v-card-title>
                                        {{ user.name}}
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="mb-2">{{ term }}</div>
                                        <div class="mb-2">Student ID: {{ user.student_id}}</div>
                                        <div >Class: {{ classId }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card flat class="d-flex pl-3">
                                    <ChartDoughnut
                                        v-if="loadedAttendance"
                                        :chart-data="attendanceData"
                                        :chart-options="chartOptions"
                                    ></ChartDoughnut>
                                    <div class="ml-3">
                                        <v-card flat>
                                            <v-card-title class="text-caption pb-0">
                                                Attendance
                                            </v-card-title>
                                            <v-card-text class="text-h4 pb-0">
                                                {{ regularityPercentage}}
                                            </v-card-text>
                                            <v-card-subtitle class="py-0">
                                                {{ regularity }}
                                            </v-card-subtitle>
                                            <v-card-subtitle class="pt-0">
                                                {{ sessionsAbsent }}
                                            </v-card-subtitle>
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card flat class="d-flex pl-3">
                                    <ChartDoughnut
                                        v-if="loadedPunctuality"
                                        :chart-data="lateData"
                                        :chart-options="chartOptions"
                                    ></ChartDoughnut>
                                    <div class="ml-3">
                                        <v-card flat>
                                            <v-card-title class="text-caption pb-0">
                                                Punctual
                                            </v-card-title>
                                            <v-card-text class="text-h4 pb-0">
                                                {{ punctualityPercentage}}
                                            </v-card-text>
                                            <v-card-subtitle class="pt-0">
                                                {{ punctuality }}
                                            </v-card-subtitle>
                                           
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                        <v-row>
                            <v-col class="pt-0">
                                <v-card class="py-0" flat>
                                    <v-card-title class="pt-0">
                                        Attendance Details
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search Date"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-data-table
                                        :headers="headers"
                                        :items="attendanceRecords"
                                        :search="search"
                                        height="40vh"
                                        fixed-header
                                    >
                                        <template v-slot:[`item.am`]="{ item }">
                                            <v-icon
                                                v-if="item.am == 'mdi-check'" 
                                                color="green"
                                            >
                                                {{ item.am }}
                                            </v-icon>
                                            <span
                                                v-else
                                            >
                                                {{ item.am }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.pm`]="{ item }">
                                            <v-icon 
                                                v-if="item.am == 'mdi-check'" 
                                                color="green"
                                            >
                                                {{ item.pm }}
                                            </v-icon>
                                            <span
                                                v-else
                                            >
                                                {{ item.pm }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.late`]="{ item }">
                                            <span 
                                                v-if="item.late == 'Y'"
                                                class="red--text"
                                            >
                                                {{ item.late }}
                                            </span>
                                            <span v-else>
                                                {{ item.late }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.time_in`]="{ item }">
                                            <span 
                                                v-if="item.late == 'Y'"
                                                class="red--text"
                                            >
                                                {{ item.time_scanned_in }}
                                            </span>
                                            <span v-else>
                                                {{ item.time_scanned_in }}
                                            </span>
                                        </template>
                                    </v-data-table>
                                </v-card>    
                            </v-col>
                        </v-row>
                       
                    </v-card-text>

                    <v-card-actions class="pt-0 pr-4">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            outlined
                            color="primary"
                            @click="displayReportMenu"
                        >Generate Report</v-btn>
                    </v-card-actions>

                    <v-overlay
                        :value="overlay"
                        opacity="0.8"
                        z-index="10" 
                        absolute 
                                         
                    >
                        
                        <v-progress-circular
                            indeterminate
                            size="64"
                            
                        ></v-progress-circular>                   
                    </v-overlay>
                </v-card>

                <v-dialog
                    v-model="dialog"
                    max-width="90vw"
                    persistent            
                >
                    <v-card height="80vh">
                        <iframe 
                            ref="pdf" 
                            style="width:100%; height:100%" 
                            frameBorder="0" 
                            :src="src"
                        ></iframe>

                        <v-overlay
                            absolute
                            :value="overlay"
                            opacity="0.5"
                            color="#A0A0A0"
                        >
                            <v-card                        
                                light
                                max-width="380"
                                class="pa-4"
                            >
                                <v-card-title>
                                    Student Attendance
                                </v-card-title>

                                <v-card-text>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                        <v-date-picker
                                                label="Select Date Range"
                                                v-on:update-range="setDateRange"
                                                v-model="dateRange"
                                                range
                                        ></v-date-picker>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                

                                <v-card-actions>
                                    
                                    <v-btn
                                        color="primary"
                                        outlined
                                        @click="closeReportMenu"
                                        class="mr-2 flex-grow-1"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        dark
                                        @click="displayReport"
                                    >
                                        Display Report
                                    </v-btn>
                                </v-card-actions>

                                <v-overlay
                                    absolute
                                    :value="loading"                    
                                    color="grey lighten-5"
                                    opacity="0.9"
                                    class="pa-5"
                                > 
                                    <v-progress-circular
                                        indeterminate 
                                        size="64"                            
                                        color="primary"                                
                                    ></v-progress-circular>                   
                                </v-overlay>
                            </v-card>

                        </v-overlay>                             
                    </v-card>

                    <v-container
                        fluid
                        class="pa-0"
                        v-if="!overlay"
                    >
                        <v-sheet>
                            <v-row
                                justify="end"
                                class="ma-0"
                            >
                                <v-btn
                                    color="primary"
                                    small
                                    dark
                                    @click="closeReport"
                                    class="my-2 mr-4"
                                >
                                    Close
                                </v-btn>
                            </v-row>
                        </v-sheet>
                    </v-container>
                
                </v-dialog>
           
        </v-row>
    </layout>
</template>

<script>
import Layout from './layouts/App';
import ChartDoughnut from './ChartDoughnut.vue';
import { mapActions, mapGetters } from 'vuex';
// import DatePickerRange from './DatePickerRange'
export default {
    name: 'Attendance',
     components: {
        Layout, 
        ChartDoughnut,  
        // DatePickerRange,               
    },
    mounted: function () {
        this.initialize();
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
        })
    },
    data: () => ({
        attendanceData: {
            labels: ['Present', 'Absent'],
                datasets: [{
                    label: 'Current Attendance',
                    data: [0,100],
                    backgroundColor: [
                        'rgb(254, 189, 75)',
                        'rgb(220, 220, 220)',
                    ],
                    borderJoinStyle: 'round',
                    borderRadius: 0,
                    hoverOffset: 4,
                    borderWidth: 0,
                    
                }]
        },
        lateData: {},
        chartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                
            },
            animation: {
                animateScale: true
            },  
            cutout: '80%',
            text: '39%',
        },
        regularityPercentage: 0,
        regularity: null,
        sessionsAbsent: null,
        punctualityPercentage: 0,
        punctuality: null,
        search: "",
        headers: [
            { text: 'Date', value: 'date_scanned'},
            { text: 'AM', value: 'am'},
            { text: 'PM', value: 'pm'},
            { text: 'Late', value: 'late'},
            { text: 'Time In', value: 'time_scanned_in'},
            { text: 'Time Out', value: 'time_scanned_out'},
        ],
        attendanceRecords: [],
        overlay: false,
        loadedAttendance: false,
        loadedPunctuality: false,
        dialog: false,
        src: "",
        dateRange: [],
        loading: false,
        term: null,
        classId: null,
    }),
    methods: {
        async initialize () {
            this.loadedAttendance = false;
            this.loadedPunctuality= false;
            this.overlay = true;
            try {
                const { data } = await this.getAttendanceRecords();
                // console.log('data: ', data);
                const { class_id } = data;
                this.classId = class_id;
                const { term } = data;
                this.term = term; 
                this.mapAttendance(data);
                this.mapRegularityChartData(data);
                this.mapPunctuality(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
            this.loadedAttendance = true;
            this.loadedPunctuality = true;
        },

        ...mapActions({
            getAttendanceRecords: 'attendance/getAttendanceRecords',
        }),

        mapAttendance ({ attendance_records }) {
            const records = [];
            attendance_records.forEach((record) => {
                let attendanceRecord = {...record};
                attendanceRecord.am = record.attendance_am;
                attendanceRecord.pm = record.attendance_pm;
                attendanceRecord.late = "--";
                if(record.time_in && !record.time_out){
                    attendanceRecord.am = "mdi-check"
                    attendanceRecord.pm = "mdi-check"
                } 
                if(record.late){
                    attendanceRecord.late = "Y"
                }
                records.push(attendanceRecord)
               
                
            });
            this.attendanceRecords = records;
            this.loadedAttendance = true;
        },

        mapRegularityChartData ({ possible_attendance, attendance}) {
            // this.attendanceData.datasets.data = [regularity, possible_attendance-regularity]
            this.attendanceData = {
                labels: ['Present', 'Absent'],
                datasets: [{
                    label: 'Current Attendance',
                    data: [attendance,possible_attendance-attendance],
                    backgroundColor: [
                        'rgb(254, 189, 75)',
                        'rgb(220, 220, 220)',
                    ],
                    borderJoinStyle: 'round',
                    borderRadius: 0,
                    hoverOffset: 4,
                    borderWidth: 0,
                    
                }]
            }

            this.regularityPercentage = possible_attendance ? ((attendance / possible_attendance)*100).toFixed(0) + "%": "0%";
            this.regularity = `Present: ${attendance}`;
            this.sessionsAbsent = `Absent: ${possible_attendance-attendance}`;
        },

        mapPunctuality ({ lates, attendance}) {
            this.lateData = {
                labels: ['Early', 'Late'],
                datasets: [{
                    label: 'Current Attendance',
                    data: [attendance-lates, lates],
                    backgroundColor: [
                        'rgb(0, 208, 203)',
                        'rgb(220, 220, 220)',
                    ],
                    borderJoinStyle: 'round',
                    borderRadius: 0,
                    hoverOffset: 4,
                    borderWidth: 0,
                    
                }]
            }
            this.punctualityPercentage = attendance ? (((attendance - lates) / attendance)*100).toFixed(0) + "%" : "0%";
            this.punctuality = `Late: ${lates} `;
        },

        setDateRange (dates) {            
            this.dateRange = dates;
        },

        closeReportMenu () {
            console.log('close menu')
            this.overlay = false;
            this.dialog = false;
            this.dateRange = [];
            this.src = '';
        },

        displayReportMenu () {
            this.overlay = true;
            this.dialog = true;
        },

        displayReport () {
            const baseUrl = process.env.VUE_APP_API_URI  + '/api/';
            this.src = baseUrl + "student-attendance-report/" + this.user.student_id + "/" +
            this.dateRange.join("/");
            this.overlay = false;
        },

         closeReport () {
            // this.closeReportMenu();
            // console.log(this.dateRange);
            this.overlay = true;
            this.src = "";
        },
    }
}
</script>